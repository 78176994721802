<template>
  <VCard class="chats">
    <VToolbar
      flat
      class="chats__header"
    >
      <VTextField
        v-model="filter"
        solo
        flat
        single-line
        hide-details
        clearable
        dense
        :label="$t('search.one')"
        background-color="#eee"
        prepend-inner-icon="search"
      />
    </VToolbar>

    <VList
      v-if="filteredChats.length"
      three-line
    >
      <VListItem
        v-for="chat in filteredChats"
        :key="chat.id"
        exact
        :to="{ query: { chat: chat.id } }"
      >
        <VListItemContent>
          <VListItemTitle class="title">
            {{ chat.title }}
          </VListItemTitle>
          <VListItemSubtitle>
            {{ chat.course_id !== 0 ? `${$t('courses.one')}: ${chat.course_title}` : $t('general_questions.one') }}
          </VListItemSubtitle>
          <VListItemSubtitle>{{ chat.sender_title }}</VListItemSubtitle>
        </VListItemContent>

        <VListItemAction>
          <VListItemActionText>{{ formatDateTime(chat.last_message_time) }}</VListItemActionText>
          <VBadge
            v-if="chat.new_messages_count"
            inline
            :value="chat.new_messages_count"
            :content="chat.new_messages_count"
          />
        </VListItemAction>
      </VListItem>
    </VList>

    <VListItem v-else>
      <VListItemContent>
        <VListItemSubtitle>
          Не найдено подходящих чатов
        </VListItemSubtitle>
      </VListItemContent>
    </VListItem>

    <VSpacer />

    <VCardActions class="chats__footer">
      <VBtn
        :to="{ query: { chat: 0 } }"
        color="primary"
        width="100%"
      >
        <VIcon left>
          add
        </VIcon>
        {{ $t('$app.create_new_chat') }}
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import { UDate } from '@/utils/date'

export default {
  name: 'ChatsList',
  props: {
    chats: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      filter: ''
    }
  },

  computed: {
    filteredChats () {
      const searchStr = (this.filter || '').toLowerCase()
      return this.chats.filter(chat => (chat.title.toLowerCase().includes(searchStr) && !chat.is_deleted))
    }
  },

  methods: {
    formatDateTime: UDate.formatDateTime
  }
}
</script>

<style lang="scss" scoped>
.chats {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: flex;
    flex-direction: column;
    height: $height-list-chats;
    overflow: auto;
  }
}

.chats__header {
  flex-grow: 0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.chats__footer {
  display: none;
  background-color: inherit;
  position: sticky;
  bottom: 0;
  z-index: 1;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: block;
  }
}
</style>
