<template>
  <div class="chat">
    <header class="chat__header">
      <VListItem
        v-if="message && message.id"
        two-line
      >
        <VListItemAction>
          <VBtn
            icon
            :title="$t('close.one')"
            @click="$emit('unselectMessage')"
          >
            <VIcon>close</VIcon>
          </VBtn>
        </VListItemAction>
        <VListItemContent>
          <VListItemTitle class="title">
            {{ message.sender_title }}
          </VListItemTitle>
          <VListItemSubtitle>{{ createdAtMessage }}</VListItemSubtitle>
        </VListItemContent>
        <VListItemAction v-if="message.is_sender">
          <VBtn
            icon
            :title="$t('edit.one')"
            @click="$emit('editMessage', message)"
          >
            <VIcon>edit</VIcon>
          </VBtn>
        </VListItemAction>
        <VListItemAction
          v-if="message.is_sender && canDeleteMessage"
          @click="$emit('deleteMessage')"
        >
          <VDialog
            v-model="deleteMessageDialog"
            max-width="400"
          >
            <template #activator="{ on, attrs }">
              <VBtn
                color="error"
                icon
                v-bind="attrs"
                :title="$t('delete.one')"
                v-on="on"
              >
                <VIcon>delete</VIcon>
              </VBtn>
            </template>
            <VCard>
              <VCardTitle>{{ $t('$app.message.delete_confirm') }}</VCardTitle>
              <VDivider />
              <VCardActions class="actions">
                <VSpacer />
                <VBtn
                  color="primary"
                  text
                  @click="deleteMessageDialog = false"
                >
                  {{ $t('cancel.one') }}
                </VBtn>
                <VBtn
                  color="error"
                  @click="deleteMessage"
                >
                  {{ $t('delete.one') }}
                </VBtn>
              </VCardActions>
            </VCard>
          </VDialog>
        </VListItemAction>
      </VListItem>

      <VListItem
        v-else
        two-line
      >
        <VListItemAction>
          <VBtn
            icon
            :to="{ name: 'messenger' }"
            exact
          >
            <VIcon>arrow_back</VIcon>
          </VBtn>
        </VListItemAction>

        <VListItemContent>
          <VListItemTitle class="title">
            {{ chatHeaderTitle }}
          </VListItemTitle>
          <VListItemSubtitle>{{ chat.sender_title }}</VListItemSubtitle>
        </VListItemContent>

        <VListItemAction v-if="chat.is_sender && canDeleteChat">
          <VDialog
            v-model="deleteChatDialog"
            max-width="400"
          >
            <template #activator="{ on, attrs }">
              <VBtn
                color="error"
                icon
                v-bind="attrs"
                :title="$t('delete.one')"
                v-on="on"
              >
                <VIcon>delete</VIcon>
              </VBtn>
            </template>
            <VCard>
              <VCardTitle>{{ $t('$app.chat.delete_confirm') }}</VCardTitle>
              <VDivider />
              <VCardActions class="actions">
                <VSpacer />
                <VBtn
                  color="primary"
                  text
                  @click="deleteChatDialog = false"
                >
                  {{ $t('cancel.one') }}
                </VBtn>
                <VBtn
                  color="error"
                  @click="deleteChat"
                >
                  {{ $t('delete.one') }}
                </VBtn>
              </VCardActions>
            </VCard>
          </VDialog>
        </VListItemAction>
      </VListItem>
    </header>

    <div class="chat__body">
      <slot />
    </div>

    <footer class="chat__footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
import { UDate } from '@/utils/date'

export default {
  name: 'ChatsListItem',

  props: {
    chat: {
      type: Object,
      required: true
    },

    message: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      deleteChatDialog: false,
      deleteMessageDialog: false,
      canDeleteChat: false,
      canDeleteMessage: true
    }
  },

  computed: {
    createdAtMessage () {
      if (!this.message?.id) { return }
      return UDate.formatDateTime(this.message.created_at)
    },

    chatHeaderTitle () {
      if (this.chat.title === 'Без темы') {
        return this.chat.course_title
      }

      return this.chat.title
    }
  },

  beforeDestroy () {
    this.unregister()
  },

  methods: {
    deleteChat () {
      this.deleteChatDialog = false
      this.$emit('deleteChat', this.chat)
    },

    deleteMessage () {
      this.deleteMessageDialog = false
      this.$emit('deleteMessage', this.message)
    },

    unregister () {
      this.deleteChatDialog = false
      this.deleteMessageDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
$chat-shadow: 0 2px 4px -1px rgb(94 99 104 / 10%), 0 4px 5px 0 rgb(94 99 104 / 10%), 0 1px 10px 0 rgb(94 99 104 / 10%);

.chat {
  height: $height-list-chats;
  display: flex;
  flex-flow: column nowrap;
}

.chat__body {
  flex: 1 1 100%;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    background-color: currentColor;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    opacity: 0.12;
    pointer-events: none;
    position: absolute;
  }
}

.chat__header {
  box-shadow: $chat-shadow;
}

.chat__footer {
  padding: 0.5rem 0;
  box-shadow: $chat-shadow;
}
</style>
